import React from 'react';
import styled from 'styled-components';
import { Button } from '../../Gui/Button';
import { StickyScrollSection as BaseStickyScrollSection } from '../../SharedSections';
import { marginTop, fullColorBackground, Color } from '../../../styles';

const SLIDE_DATA = [
  {
    heading: 'Always be ready and get it done ',
    body: 'Customers trust us to build things that work and we take that seriously. Our team will overcome obstacles, find solutions and deliver exceptional results.',
  },
  {
    heading: 'Push boundaries ',
    body: 'Be innovative and continuously improve. Challenge ourselves and each other to be better everyday. Dismantle engrained thinking to create opportunities.',
  },
  {
    heading: 'Be admirable and Act with Integrity',
    body: 'We will do what we say and choose transparency, candor, respect, and kindness.',
  },
  {
    heading: 'Be Customer Obsessed',
    body: 'Our success depends on improving the lives of our customers, let’s make it our priority to know them by engaging, educating and empowering them with our people, products and services.',
  },
  {
    heading: 'We Win With Diversity',
    body: 'Diversity is more than just the sum of our many parts. It’s our superpower — not just the right thing to do but how we win. Whether through diversity of background, experience, opinions, or views, it’s how we build a sustainable business. We are creating a better system which embraces equity and provides something for everyone.',
  },
  {
    heading: 'Enjoy the Journey',
    body: 'We take the time to stop and acknowledge where we came from so that we don’t miss how impressive the climb is.',
  },
];

const StickyScrollSection = styled(BaseStickyScrollSection)`
  .content-container {
    ${marginTop(48, 48, 92)};
  }

  ::after {
    background: linear-gradient(180deg, rgba(249, 249, 249, 0) 0%, #f9f9f9 81.43%);
  }
`;

const Values = ({ assets }) => {
  return (
    <StickyScrollSection
      slideData={SLIDE_DATA}
      assets={assets}
      mobileCarouselHeight={412}
      imageNames={{ mobile: 'values-mobile', tablet: 'values-tablet', desktop: 'values-desktop' }}>
      <h2>Our values</h2>
    </StickyScrollSection>
  );
};

export default Values;
