import React, { useState } from 'react';
import styled from 'styled-components';
import {
  maxWidth,
  Fonts,
} from '../../../styles';

const AccordionWrapper = styled.div`
${maxWidth('32.7rem', '68.8rem', '120rem')};
  border: 1px solid #ccc;
`;

const AccordionTitle = styled.h3`
${Fonts.WORK_SANS};
  font-size: 22px;
  font-weight: bold;
`;

const AccordionLocation = styled.h4`
${Fonts.WORK_SANS};
  font-size: 16px;
  line-height: 1.5;
  color: #333;
`;

const AccordionOpening = styled.div`
${Fonts.WORK_SANS};
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  color: #000;
`;

const AccordionItem = styled.a`
${Fonts.WORK_SANS};
cursor: pointer;
font-size: 16px;
font-weight: 600;
text-align: left;
padding: 1rem 0;
line-height: 2.5;
  display: block;
  text-decoration: none;
  color: #333;

  &:not(:last-child) {
    border-bottom: 1px solid #ccc;
  }

  &:first-child {
    border-top: 1px solid #ccc;
  }

  &:hover {
    background-color: #fcfcfc;
  }
`;

const AccordionHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  cursor: pointer;
`;

const AccordionContent = styled.div`
  padding: 1rem;
`;

const Icon = styled.span`
  font-size: 3rem;
`;

const AccordionMenu = ({ title, items}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <AccordionWrapper>
      <AccordionHeader onClick={handleToggle}>
        <AccordionTitle>{title}</AccordionTitle>
        <Icon>{isOpen ? '-' : '+'}</Icon>
      </AccordionHeader>
      {isOpen && (
        <AccordionContent>
          {items.map((item, index) => (
            <AccordionItem key={index} href={item.url}>
              <AccordionOpening>{item.opening}</AccordionOpening>
              <AccordionLocation>{item.location}</AccordionLocation>
              </AccordionItem>
            
          ))}
        </AccordionContent>
      )}
    </AccordionWrapper>
  );
};

export default AccordionMenu;