import React from 'react';
import styled from 'styled-components';
import {
  flex,
  owlSelector,
  marginTop,
  maxWidth,
  Color,
  typographyByBreakpoint,
  xxlMobile,
  h2Mobile,
  xxlDesktop,
  h2Desktop,
  bodyDefault,
  marginLeft,
  marginBottom,
  position,
  fullColorBackground,
  width,
  height,
} from '../../../styles';
// @ts-ignore
import Image from '../../../images/careers/hero-image.jpg';
import { Button } from '../../Gui/Button';

const Wrapper = styled.section`
  ${maxWidth('32.7rem', '68.8rem', '120rem')};
  ${marginBottom(64, 120, 148)};
  margin-inline: auto;
  position: relative;
`;

const Content = styled.div`
  ${marginTop(166, 152, 166)};
  ${flex('flex-start', 'flex-start', 'column')}
  ${maxWidth('32.7rem', '51.2rem', '80.8rem')};
  ${owlSelector('top', 16)};

  h1 {
    ${typographyByBreakpoint(xxlMobile, xxlMobile, xxlDesktop)};
    color: ${Color.SEAFOAM_GREEN};
  }

  div {
    ${maxWidth('unset', '48.2rem', '66.5rem')};
    ${marginLeft(0, 30, 143)}
    ${owlSelector('top', 16)};

    h2 {
      ${typographyByBreakpoint(h2Mobile, h2Mobile, h2Desktop)};
      color: ${Color.BLACK};
      ${maxWidth('32.7rem', '46rem', '66.5rem')};
    }

    p {
      ${bodyDefault};
    }
    li {
      ${bodyDefault}
      ${marginLeft(40, 40
        , 45)}
    }
    h3 {
      font-size: 2.5rem;
    }
  }
`;

const OpeningsButton = styled(Button)`
  margin-top: 32px;
  ${marginLeft(0, 30, 143)}
  height: 5.1rem;
  z-index: 1;
  position: relative;
  line-height: 1.5;
`;

const Hero = () => {
  return (
    <Wrapper>
      <Content>
        <h1>Careers</h1>
        <div>
          <h2>Our mission is to empower communities to help them advance economically and create generational wealth. </h2>
          <p>
            We are committed to our mission, our communities and building a team that exemplifies and lives our values everyday. We
            are proud to be an Equal Opportunity Employer and are committed to providing an environment of mutual respect.
          </p>
        <h3>We offer:</h3>
        <ul>
          <li>Remote work environment with flexible hours</li>
          <li>Health, dental and vision insurance</li>
          <li>FSA for Health and dependent care</li>
          <li>Paid vacation</li>
        </ul>
        </div>
      </Content>
      <OpeningsButton url={'#openings'} theme={'DARK_THEME'}>
        View Current Openings
      </OpeningsButton>
    </Wrapper>
  );
};

export default Hero;
