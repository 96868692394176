import * as React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { graphql } from 'gatsby';

import WebsiteDefaultPage from '../components/Page/Base';
import { Hero, Values, Openings } from '../components/PageSpecific/careers';
import { height, position, width, Width } from '../styles';
import { LIGHT_DARK } from '../constants';
// @ts-ignore
import Image from '../images/careers/hero-image.jpg';

const GlobalStyleOverride = createGlobalStyle`
  @media print, screen and (min-width: ${Width.BREAK_MD}px){
    html {
      overflow: unset;
    }
  }
`;

const Wrapper = styled.div`
  position: relative;
`;

const Background = styled.div`
  background: url(${Image}) no-repeat center;
  background-size: cover;
  position: absolute;
  //width: 100vw;
  ${width('140vw', '110vw', '110vw')};
  ${height('40rem', '64rem', '90rem')};
  ${position('top', -166, -166, -166)};
  ${position('left', -150, -117, 'calc((-100vw + 1200px) / 2 - 40px)')};
`;

const CareersPage = ({ data }) => {
  const { careersImages, customerOpsPostings, peopleOpsPostings } = data;

  return (
    <>
      <GlobalStyleOverride />
      <WebsiteDefaultPage pageState={{ theme: LIGHT_DARK.LIGHT }}>
        <Wrapper>
          {/* <Background /> */}
          <Hero />
          <Values assets={careersImages} />
          <Openings customerOps={customerOpsPostings} peopleOps={peopleOpsPostings} />
        </Wrapper>
      </WebsiteDefaultPage>
    </>
  );
};

export const query = graphql`
  query CareersPageQuery {
    careersImages: allFile(
      filter: { extension: { regex: "/(jpg)|(png)|(jpeg)/" }, relativeDirectory: { eq: "careers" } }
      sort: { fields: base, order: ASC }
    ) {
      edges {
        node {
          id
          base
          name
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }
      }
    }
    customerOpsPostings: allSanityCustomerOperationsJobOpenings {
      edges {
        node {
          id
          role
          jobPostURL
          city
          state
          type
        }
      }
    }
    peopleOpsPostings: allSanityPeopleOperationsJobOpenings {
      edges {
        node {
          id
          role
          jobPostURL
          city
          state
          type
        }
      }
    }
  }
`;

export default CareersPage;