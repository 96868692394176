import React from 'react';
import styled from 'styled-components';
import {
  bodyDefault,
  Color,
  h1Desktop,
  h1Mobile,
  h3Desktop,
  marginBottom,
  marginTop,
  maxWidth,
  typographyByBreakpoint,
  Fonts,
} from '../../../styles';
import AccordionMenu from './AccordionMenu';

const Wrapper = styled.section`
  ${maxWidth('32.7rem', '68.8rem', '97.8rem')};
  ${marginTop(80, 120, 210)};
  ${marginBottom(80, 100, 160)};
  margin-inline: auto;
`;

const OpeningsTitle = styled.section`
  ${Fonts.WORK_SANS};
  font-size: 25px;
  text-align: center;
  font-weight: 500;
`;

const Content = styled.div`
  h2 {
    ${typographyByBreakpoint(h1Mobile, h1Mobile, h1Desktop)};
  }

  > div {
    ${maxWidth('32.7rem', '68.8rem', '97.8rem')};
    background-color: ${Color.WHITE};
    box-shadow: 33.7444px 33.7444px 60.7399px rgba(166, 180, 200, 0.1),
      0 14.1616px 14.1616px rgba(0, 0, 0, 0.08);
    text-align: center;
    padding: .5rem 0;
    border-radius: 1.5rem;
    margin: 3rem auto;

    p {
      &:first-of-type {
        ${h3Desktop};
      }

      &:last-of-type {
        ${bodyDefault}
        margin-top: 1.5rem;
      }
    }
  }
`;

const Openings = ({ customerOps, peopleOps }) => {
  const customerOperationsOpenings = customerOps.edges.map(edge => {
    const { id, role, jobPostURL, city, state } = edge.node;
    return {
      title: id,
      opening: role,
      location: `${city}, ${state}`,
      url: jobPostURL,
    };
  });

  const peopleOperationsOpenings = peopleOps.edges.map(edge => {
    const { id, role, jobPostURL, city, state } = edge.node;
    return {
      title: id,
      opening: role,
      location: `${city}, ${state}`,
      url: jobPostURL,
    };
  });

  const noCurrentOpenings = [
    {
      title: null,
      opening: 'No Openings Currently Available',
      location: null,
      url: null,
    },
  ];

  const isCustomerOperationsDataAvailable = customerOperationsOpenings.length > 0;
  const isPeopleOperationsDataAvailable = peopleOperationsOpenings.length > 0;

  return (
    <Wrapper id="openings">
      <Content>
        <OpeningsTitle>All Open Roles</OpeningsTitle>
        {isCustomerOperationsDataAvailable ? (
          <AccordionMenu title="Customer Operations" items={customerOperationsOpenings} />
        ) : (
          <AccordionMenu title="Customer Operations" items={noCurrentOpenings} />
        )}
        {isPeopleOperationsDataAvailable ? (
          <AccordionMenu title="People Operations" items={peopleOperationsOpenings} />
        ) : (
          <AccordionMenu title="People Operations" items={noCurrentOpenings} />
        )}
      </Content>
    </Wrapper>
  );
};

export default Openings;